import {
    GENERATE_ANSWER,
    GENERATE_ANSWER_SUCCESS,
    GENERATE_ANSWER_FAILURE,
    GENERATE_QUESTION,
    GENERATE_QUESTION_SUCCESS,
    GENERATE_QUESTION_FAILURE,
    GET_QUESTIONS,
    GET_QUESTIONS_SUCCESS,
    GET_QUESTIONS_FAILURE,
    POST_QUESTION,
    POST_QUESTION_SUCCESS,
    POST_QUESTION_FAILURE,
    CODING_PERMISSION,
    CODING_PERMISSION_SUCCESS,
    CODING_PERMISSION_FAILURE,
    GET_QUESTION_LIBRARY,
    GET_QUESTION_LIBRARY_SUCCESS,
    GET_QUESTION_LIBRARY_FAILURE,
    APTITUDE_PERMISSION,
    APTITUDE_PERMISSION_SUCCESS,
    APTITUDE_PERMISSION_FAILURE,
    CONVERSATION_PERMISSION,
    CONVERSATION_PERMISSION_SUCCESS,
    CONVERSATION_PERMISSION_FAILURE,
} from './types';
export const conversationPermission = () => ({
    type: CONVERSATION_PERMISSION,
});
export const conversationPermissionSuccess = (payload) => ({
    type: CONVERSATION_PERMISSION_SUCCESS,
    payload,
});
export const conversationPermissionFailure = () => ({
    type: CONVERSATION_PERMISSION_FAILURE,
});
export const codingPermission = () => ({
    type: CODING_PERMISSION,
});
export const codingPermissionSuccess = (payload) => ({
    type: CODING_PERMISSION_SUCCESS,
    payload,
});
export const codingPermissionFailure = () => ({
    type: CODING_PERMISSION_FAILURE,
});
export const aptitudePermission = () => ({
    type: APTITUDE_PERMISSION,
});
export const aptitudePermissionSuccess = (payload) => ({
    type: APTITUDE_PERMISSION_SUCCESS,
    payload,
});
export const aptitudePermissionFailure = () => ({
    type: APTITUDE_PERMISSION_FAILURE,
});
export const createOrUpdateQuestion = (payload) => ({
    type: POST_QUESTION,
    payload,
});
export const createOrUpdateQuestionSuccess = () => ({
    type: POST_QUESTION_SUCCESS,
});
export const createOrUpdateQuestionFailure = (payload) => ({
    type: POST_QUESTION_FAILURE,
    payload,
});
export const getQuestions = (payload) => ({
    type: GET_QUESTIONS,
    payload,
});
export const getQuestionsSuccess = (payload) => ({
    type: GET_QUESTIONS_SUCCESS,
    payload,
});
export const getQuestionsFailure = () => ({
    type: GET_QUESTIONS_FAILURE,
});
export const generateQuestion = (payload) => ({
    type: GENERATE_QUESTION,
    payload,
});
export const generateQuestionSuccess = () => ({
    type: GENERATE_QUESTION_SUCCESS,
});
export const generateQuestionFailure = () => ({
    type: GENERATE_QUESTION_FAILURE,
});
export const generateAnswer = (payload) => ({
    type: GENERATE_ANSWER,
    payload,
});
export const generateAnswerSuccess = () => ({
    type: GENERATE_ANSWER_SUCCESS,
});
export const generateAnswerFailure = (payload) => ({
    type: GENERATE_ANSWER_FAILURE,
    payload,
});
export const getQuestionLibrary = () => ({
    type: GET_QUESTION_LIBRARY,
});
export const getQuestionLibrarySuccess = (payload) => ({
    type: GET_QUESTION_LIBRARY_SUCCESS,
    payload,
});
export const getQuestionLibraryFailure = () => ({
    type: GET_QUESTION_LIBRARY_FAILURE,
});
