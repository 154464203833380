/* eslint-disable react-hooks/rules-of-hooks */
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { KEY_WORD, fileExtension, fileType, COOKIES_LIST } from './constant';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
export const secretKey = 'democlient:democlientsecret';
export const encryptKey = 'y347t567t347InCrUiTeRb6t5b3487y5';
export const isUpperCase = (value) => /[A-Z]/.test(value);
export const isLowerCase = (value) => /[a-z]/.test(value);
export const isContainNumber = (value) => /\d/.test(value);
export const isSpecialChar = (value) => /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(value);

export const getLocalStorageItem = (key) => localStorage.getItem(key);
export const setLocalStorageItem = (key, value) => localStorage.setItem(key, value);
export const removeLocalStorageItem = (key) => localStorage.removeItem(key);
const envMode = process.env.REACT_APP_ENV;
export const convertIntoQueryString = (data) => {
    return qs.stringify(data);
};

export const checkFileValidation = (files, type) => {
    let isValid = false;
    let message = 'The uploaded file is not a valid file type. Only .doc, .docx, and .pdf file types are allowed.';

    let zipMessage =
        'Invalid File Format: Please upload a valid zip file. Only files with the .zip extension are allowed.';

    let xlsxMessage =
        'Invalid File Format: Please upload a valid xlsx file. Only files with the .xlsx extension are allowed.';

    if (type === 'image') {
        if (files.type === 'image/jpeg' || files.type === 'image/png' || files.type === 'image/jpg') {
            isValid = true;
        }
    } else if (type === 'zip') {
        if (
            files.type === 'zip' ||
            files.type === 'application/octet-stream' ||
            files.type === 'application/zip' ||
            files.type === 'application/x-zip' ||
            files.type === 'application/x-zip-compressed'
        ) {
            isValid = true;
        }
    } else if (type === 'xlsx') {
        if (files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            isValid = true;
        }
    } else {
        if (
            files.type === 'application/pdf' ||
            files.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            files.type === 'application/msword'
        ) {
            isValid = true;
        }
    }
    if (!isValid) {
        if (type === 'zip') {
            return {
                status: isValid,
                msg: zipMessage,
            };
        } else if (type === 'xlsx') {
            return {
                status: isValid,
                msg: xlsxMessage,
            };
        } else {
            return {
                status: isValid,
                msg: message,
            };
        }
    } else {
        return {
            status: isValid,
            msg: '',
        };
    }
};
export const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds} mins`;
};

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const checkZipFileSize = (file, type) => {
    if (file.size > 100 * 1024 * 1024) {
        if (type === 'zip') {
            return {
                status: false,
                msg: 'The Zip file size exceeds the 100MB limit',
            };
        } else if (type === 'xlsx') {
            return {
                status: false,
                msg: 'The Xlsx file size exceeds the 100MB limit',
            };
        }
    } else {
        return {
            status: true,
            msg: '',
        };
    }
};

export const checkFileBlank = async (file) => {
    if (file && file.size > 5 * 1024 * 1024) {
        // if (file.size > 5 * 1024 * 1024) {
        // Check if file size is greater than 10 MB
        return {
            status: false,
            msg: 'The file size exceeds the 5MB limit',
        };
        // }
        //ISS-1163 Have commented the code of fileValidator that checks if the uploaded file is blank or not as for now we don't want the functionality that checks for blank file
        // try {
        //     const formData = new FormData();
        //     formData.append('file', file);
        //     const response = await axios.post(
        //         `${generateUrl('interview_backend')}/interviews/fileValidator`,
        //         formData,
        //         {
        //             headers: {
        //                 'Content-Type': 'multipart/form-data',
        //                 Authorization: `Bearer ${getAccessToken}`,
        //             },
        //         }
        //     );
        //     return { status: response?.data?.status, msg: 'The uploaded file is empty' };
        // } catch (error) {
        //     return {
        //         status: 'error',
        //         msg: 'An error occurred during file validation',
        //     };
        // }
    } else {
        return { status: 'error', msg: 'File is empty or undefined' };
    }
};

export const capitalizeAll = (s) => {
    if (typeof s !== 'string') return '';
    return s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
export const useOutsideAlerter = (ref, setIsOpen) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setIsOpen]);
};

export const newUseOutsideAlerter = (ref, callback) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};

export const Toast = ({ msg }) => <>{msg}</>;

export const toastMessage = (type, message) => {
    toast[type](message, { autoClose: '5000', toastId: message });
};

export const getYearsFrom1900ToCurrentYear = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const totalYear = currentYear - 1900;
    const year = [];
    for (let i = totalYear; i >= 0; i--) {
        year.push(currentYear - i);
    }
    return year;
};
const date = new Date();
const year = date.toISOString().split('-')[0];
const month = date.toISOString().split('-')[1];
export const graphDropDownInterviewMonthsOption = [
    {
        value: `&from=2013-01-01T00:00:00.000Z&to=${year}-${month}-31T23:59:59.000Z`,
        label: 'All',
    },
    {
        value: `&from=${year}-01-01T00:00:00.000Z&to=${year}-01-31T23:59:59.000Z`,
        label: 'Jan',
    },
    {
        value: `&from=${year}-02-01T00:00:00.000Z&to=${year}-02-28T23:59:59.000Z`,
        label: 'Feb',
    },
    {
        value: `&from=${year}-03-01T00:00:00.000Z&to=${year}-03-31T23:59:59.000Z`,
        label: 'Mar',
    },
    {
        value: `&from=${year}-04-01T00:00:00.000Z&to=${year}-04-30T23:59:59.000Z`,
        label: 'Apr',
    },
    {
        value: `&from=${year}-05-01T00:00:00.000Z&to=${year}-05-31T23:59:59.000Z`,
        label: 'May',
    },
    {
        value: `&from=${year}-06-01T00:00:00.000Z&to=${year}-06-30T23:59:59.000Z`,
        label: 'Jun',
    },
    {
        value: `&from=${year}-07-01T00:00:00.000Z&to=${year}-07-31T23:59:59.000Z`,
        label: 'Jul',
    },
    {
        value: `&from=${year}-08-01T00:00:00.000Z&to=${year}-08-31T23:59:59.000Z`,
        label: 'Aug',
    },
    {
        value: `&from=${year}-09-01T00:00:00.000Z&to=${year}-09-30T23:59:59.000Z`,
        label: 'Sep',
    },
    {
        value: `&from=${year}-010-01T00:00:00.000Z&to=${year}-10-31T23:59:59.000Z`,
        label: 'Oct',
    },
    {
        value: `&from=${year}-011-01T00:00:00.000Z&to=${year}-11-30T23:59:59.000Z`,
        label: 'Nov',
    },
    {
        value: `&from=${year}-12-01T00:00:00.000Z&to=${year}-12-31T23:59:59.000Z`,
        label: 'Dec',
    },
];
export const TimeRangeOptions = [
    {
        label: 'Today',
        value: {
            from: moment().format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
    {
        label: 'Yesterday',
        value: {
            from: moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().subtract(1, 'days').format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
    {
        label: 'Last 7 Days',
        value: {
            from: moment().subtract(7, 'days').format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
    {
        label: 'Last 30 Days',
        value: {
            from: moment().subtract(30, 'days').format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
    {
        label: 'All',
        value: {
            from: moment().subtract(10, 'years').format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
];
export const getOptions = (data) => {
    const newArray = [];
    data.forEach((element) =>
        newArray.push({
            value: element,
            label: element,
        })
    );
    return newArray;
};

export const localStorageName = [
    { key: 'token', value: 'iCtK' },
    { key: 'viewtype', value: 'viewType' },
    { key: 'refreshToken', value: 'iCrT' },
    { key: 'incserveToken', value: 'iCiT' },
    { key: 'incserveTokenKey', value: 'iCiTi' },
    { key: 'userData', value: `iCuD_${envMode}` },
    { key: 'companyData', value: `iCcD_${envMode}` },
    { key: 'productId', value: `iCpD_${envMode}` },
    { key: 'permissions', value: `ic_p_${envMode}` },
    { key: 'credit', value: 'iCcR' },
    { key: 'expiryTime', value: 'iCeT' },
    { key: 'feedbackReport', value: 'iCfd' },
    { key: 'interviewData', value: 'intDat' },
];

export const exportToCSV = (apiData, fileName) => {
    if (apiData?.length > 0) {
        const objectMaxLength = [];
        const wscols = [];
        for (let i = 0; i < apiData.length; i++) {
            let value = Object.values(apiData[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] === 'number') {
                    objectMaxLength[j] = 10;
                } else {
                    objectMaxLength[j] = objectMaxLength[j] >= value[j]?.length ? objectMaxLength[j] : value[j]?.length;
                }
            }
        }
        for (let i = 0; i < Object.keys(apiData[0]).length; i++) {
            wscols.push({ width: objectMaxLength[i] });
        }

        const ws = XLSX.utils.json_to_sheet(apiData);
        ws['!cols'] = wscols;
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    } else {
        toast.error('No Record to Export', {
            autoClose: 5000,
            toastId: 'No Record to Export',
        });
    }
};

export const downloadFile = async (fileUrl, fileName) => {
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        // Cleanup
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export const accessRoutes = (resource) => {
    let userType = localStorageJsonParseDecrypt('userData') && localStorageJsonParseDecrypt('userData').userType;
    let permissions = localStorageJsonParseDecrypt('permissions');
    permissions = permissions && permissions.filter((itm) => itm.name === resource);
    if (permissions[0]?.status === 'active' || userType === 'employer') {
        return true;
    } else {
        return false;
    }
};

export const checkRoutes = (resource) => {
    let userType = localStorageJsonParseDecrypt('userData') && localStorageJsonParseDecrypt('userData').userType;
    let permissions = localStorageJsonParseDecrypt('permissions');
    permissions = permissions && permissions.filter((itm) => itm.name === resource);
    if (permissions[0]?.status === 'active' || userType === 'employer') {
        return true;
    } else {
        return false;
    }
};

export const remarksChecker = (value) => {
    // Remove any characters that are not allowed, excluding new lines
    const sanitizedValue = value.replace(/[^A-Za-z0-9\s\n!@#$%&*()_\-+=\[\]{}\/?.,;:'"\/]/g, '');
    return sanitizedValue;
};

export const jobTitleChecker = (value) => {
    return value.replace(/[^A-Za-z0-9\s\#\(\)\@\!\*\.\+]/g, '');
};

export const redirectPermission = () => {
    localStorage.clear();
    clearCookies();
    window.location.replace(mainWebsite + '/employer/login');
};

const commonLocalStorageDecryptCondition = (storageKey, isJson) => {
    let Key = storageKey;
    localStorageName
        .filter((itm) => itm.key === storageKey)
        .map((itm) => {
            Key = itm.value;
        });
    var data = '';
    if (COOKIES_LIST.includes(storageKey)) {
        if (Cookies.get(Key)) {
            var bytes = CryptoJS.AES.decrypt(Cookies.get(Key), encryptKey);
            data = bytes.toString(CryptoJS.enc.Utf8);
            if (data && isJson) {
                data = JSON.parse(data);
            }
        }
    } else {
        if (getLocalStorageItem(Key)) {
            var bytes = CryptoJS.AES.decrypt(getLocalStorageItem(Key), encryptKey);
            data = bytes.toString(CryptoJS.enc.Utf8);
            if (data && isJson) {
                data = JSON.parse(data);
            }
        }
    }
    return data;
};

export const localStorageEncrypt = (storageKey, data) => {
    let Key = storageKey;
    localStorageName
        .filter((itm) => itm.key === storageKey)
        .map((itm) => {
            Key = itm.value;
        });
    var value = CryptoJS.AES.encrypt(data, encryptKey).toString();
    if (COOKIES_LIST.includes(storageKey)) {
        Cookies.set(Key, value, {
            domain: window.location.hostname === KEY_WORD?.LOCALHOST ? KEY_WORD?.LOCALHOST : KEY_WORD?.INCRUITER_DOMAIN,
        });
    } else {
        setLocalStorageItem(Key, value);
    }
};

export const localStorageDecrypt = (storageKey) => {
    return commonLocalStorageDecryptCondition(storageKey, false);
};

export const localStorageJsonParseDecrypt = (storageKey) => {
    return commonLocalStorageDecryptCondition(storageKey, true);
};

export const localStorageRemove = (storageKey) => {
    let Key = storageKey;
    localStorageName
        .filter((itm) => itm.key === storageKey)
        .map((itm) => {
            Key = itm.value;
        });
    localStorage.removeItem(Key);
};

export const ArrayForExpRange = () => {
    const tmpArray = [];
    Array.from({ length: 41 }, (_, index) => index).map((itm) => {
        tmpArray.push({
            value: itm,
            label: itm,
        });
    });
    return tmpArray;
};
export const jobTypeOptions = [
    {
        value: 'Full-time',
        label: 'Full-time',
    },
    {
        value: 'Part-time',
        label: 'Part-time',
    },
    {
        value: 'Contract',
        label: 'Contract',
    },
    {
        value: 'Internship',
        label: 'Internship',
    },
];

export const salaryRangeOptions = [
    {
        value: '0 - 3 Lakhs',
        label: '0 - 3 Lakhs',
    },
    {
        value: '3 - 6 Lakhs',
        label: '3 - 6 Lakhs',
    },
    {
        value: '6 - 10 Lakhs',
        label: '6 - 10 Lakhs',
    },
    {
        value: '10 - 15 Lakhs',
        label: '10 - 15 Lakhs',
    },
    {
        value: '15 - 25 Lakhs',
        label: '15 - 25 Lakhs',
    },
    {
        value: '25 - 50 Lakhs',
        label: '25 - 50 Lakhs',
    },
    {
        value: '50 - 75 Lakhs',
        label: '50 - 75 Lakhs',
    },
    {
        value: '75 - 100 Lakhs',
        label: '75 - 100 Lakhs',
    },
    {
        value: '1 - 5 Cr',
        label: '1 - 5 Cr',
    },
];
export const skillChecker = (value) => {
    return value.replace(/[^A-Za-z0-9\s\,\(\)\!\@\*\.\-\+\#]/g, '');
};
export const numberChecker = (value) => {
    return value.replace(/\D/g, '');
};
export const nameChecker = (value) => {
    const modifiedValue = value.replace(/[^A-Za-z\s\.]/g, '');
    if (modifiedValue.length > 80) {
        return modifiedValue.slice(0, 80);
    }
    return modifiedValue;
};

export const reload = () => {
    return window.location.reload();
};
export const mailChecker = (value) => {
    return value.replace(/[^a-zA-Z0-9\_\-\@\.\+]/g, '');
};
export const contactNumberChecker = (value) => {
    return value.replace(/[^0-9]/g, '').slice(0, 10);
};

export const validateEmail = (email) => {
    const regex =
        /^(?!\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.(?:com|gov|org|edu|net|in|co(?:\.[a-zA-Z]{2})?))$/;

    return regex.test(email);
};

export const notificationRedirect = (data, navigate) => {
    const getPath = (productName, itemType) => {
        const itemTypeLower = itemType?.toUpperCase()?.trim();
        switch (itemTypeLower) {
            case 'INTERVIEWS':
                if (productName?.toLowerCase() === KEY_WORD.INCBOT) return '/candidates';
                return '/my-interviews';
            case 'QUESTIONS':
                if (productName?.toLowerCase() === KEY_WORD.INCBOT) return '/positions';
                return '/my-positions';
            case 'POSITIONS':
                if (productName?.toLowerCase() === KEY_WORD.INCBOT) return '/positions';
                return '/my-positions';
            case 'CANDIDATEBANK':
                if (productName?.toLowerCase() === KEY_WORD.INCBOT) return '/candidate-bank';
            case 'SUPPORT':
                return '/support';
            case 'PANELISTS':
                return '/create-panelist';
            case 'ROLES':
                return '/roles-subuser-list?role';
            case 'SUBUSERS':
                return '/roles-subuser-list';
            default:
                return '';
        }
    };
    const getOperator = (urlPath) => {
        return urlPath.includes('?') ? '&' : '?';
    };
    const search = data?.data?.search;
    const itemType = data?.data?.itemType;
    const urlPath = getPath(data?.productId?.name, itemType);
    if (urlPath) {
        const productName = data.productId?.name;
        if (data?.data?.itemType && productName?.toLowerCase() === KEY_WORD?.INCBOT?.toLowerCase())
            navigate(urlPath + `${getOperator(urlPath)}search=${encodeURIComponent(search)}`);
        else window.location.href = `${generateUrl('account')}${urlPath}?search=${encodeURIComponent(search)}`;
    }
};

export const hasPageAccess = (permissions, mainResource, resourceName, action) => {
    const userType = localStorageJsonParseDecrypt('userData')?.userType;

    if (userType === 'employer') {
        return true;
    }

    if (mainResource === '') {
        return true;
    }

    const mainResourcePermission = permissions?.find(
        (permission) => permission?.key?.toLowerCase() === mainResource?.toLowerCase()
    );

    if (mainResourcePermission?.status) {
        const resourcePermission = mainResourcePermission?.resources?.find(
            (resource) => resource?.key?.toLowerCase() === resourceName?.toLowerCase()
        );
        if (resourcePermission?.actions?.hasOwnProperty('status')) {
            return resourcePermission?.actions?.status;
        } else {
            return resourcePermission?.actions[action];
        }
    } else {
        return false;
    }
};

export const hasProductAccess = (permissions, mainResourceName) => {
    const userType = localStorageJsonParseDecrypt('userData')?.userType;

    if (mainResourceName === 'incserve' || userType === 'employer') {
        return true;
    }

    const resourcePermission = permissions?.find(
        (permission) => permission?.name && permission?.name.toLowerCase() === mainResourceName?.toLowerCase()
    );

    return resourcePermission?.status || false;
};

export const hasActionAccess = (permissions, mainResource, resourceName, action) => {
    const userType = localStorageJsonParseDecrypt('userData')?.userType;

    if (userType === 'employer') {
        return true;
    }

    if (mainResource === '') {
        return true;
    }

    const mainResourcePermission = permissions?.find(
        (permission) => permission?.key?.toLowerCase() === mainResource?.toLowerCase()
    );

    const resourcePermission = mainResourcePermission?.resources?.find(
        (resource) => resource?.key?.toLowerCase() === resourceName?.toLowerCase()
    );

    if (resourcePermission?.actions?.hasOwnProperty('status')) {
        return resourcePermission?.actions?.status;
    } else if (resourcePermission?.actions) {
        return resourcePermission?.actions[action];
    }

    return false;
};

export const commonPageList = [
    '/my-profile',
    '/accounts-billing',
    '/settings',
    '/subuser',
    '/contact-enquiries',
    '/roles',
    '/roles-subuser-list',
    '/product',
    '/plan-pricing',
    '/payment-information',
    '/payment-success',
    '/notification',
];
export const generateUrl = (urlMode) => {
    const envMode = process.env.REACT_APP_ENV;
    let frontendUrlPreFix, backendUrlPreFix, externalUrl, interviewUrlPreFix, aiBackendUrlPrefix;
    let finalUrl;
    if (envMode === 'prod') {
        interviewUrlPreFix = 'meeting';
        frontendUrlPreFix = '';
        backendUrlPreFix = 'api.';
        externalUrl = '';
        aiBackendUrlPrefix = '';
    } else if (envMode === 'test' || envMode === 'preprod') {
        interviewUrlPreFix = 'test.v1.interview';
        frontendUrlPreFix = 'test.v1.';
        backendUrlPreFix = 'test.api.';
        externalUrl = 'preprod.';
        aiBackendUrlPrefix = 'test-';
    } else {
        interviewUrlPreFix = 'dev.v1.interview';
        frontendUrlPreFix = 'dev.v1.';
        backendUrlPreFix = 'dev.api.';
        externalUrl = 'dev.';
        aiBackendUrlPrefix = 'dev-';
    }
    if (urlMode === 'incserve') {
        finalUrl = `https://${externalUrl}incserve.incruiter.com`;
    } else if (urlMode === 'website') {
        finalUrl = `https://${frontendUrlPreFix}incruiter.com`;
    } else if (urlMode === 'account') {
        finalUrl = `https://${frontendUrlPreFix}account.incruiter.com`;
    } else if (urlMode === 'incbot') {
        finalUrl = `https://${frontendUrlPreFix}incbot.incruiter.com`;
    } else if (urlMode === 'job_board') {
        finalUrl = `https://${frontendUrlPreFix}jobs.incruiter.com`;
    } else if (urlMode === 'career_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/candidate-system/api/v1`;
    } else if (urlMode === 'interview_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/interview-system/api/v1`;
    } else if (urlMode === 'skills_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/skills-system/api/v1`;
    } else if (urlMode === 'user_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/user-auth-system/api/v1`;
    } else if (urlMode === 'calendar_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/calendar-system/api/v1`;
    } else if (urlMode === 'subscription_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/subscription-system/api/v1`;
    } else if (urlMode === 'video_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/video-feedback-system/api/v1`;
    } else if (urlMode === 'meeting') {
        finalUrl = `https://${interviewUrlPreFix}.incruiter.com/interview`;
    } else if (urlMode === 'ai_backend') {
        finalUrl = `https://${aiBackendUrlPrefix}interview-system.incruiter.com/api/v1`;
    }
    return finalUrl;
};
export const url = generateUrl('user_backend');
export const incserveUrl = generateUrl('incserve');
export const mainWebsite = generateUrl('website');
export const incserveStagingUrl = generateUrl('incserve');
export const getAssetSrc = (assetName) => {
    return `https://incruiter.blob.core.windows.net/frontend/frontend-incbot/assets/${assetName}`;
};

export const getIncserveToken = Cookies.get(`incserveToken_${envMode}`) ?? '';
export const getAccessToken = Cookies.get(`token_${envMode}`) ?? '';

export const clearCookies = () => {
    const cookieNames = ['token', 'incserveToken', 'iCuD', 'iCcD', 'iCpD', 'ic_p'];
    cookieNames.forEach((name) => {
        Cookies.remove(`${name}_${envMode}`, { path: '/', domain: window.location.hostname });
    });
};

export const clearOldCookies = () => {
    let cookieNames = [];
    const cookiesDevName = [
        'token_dev',
        'incserveToken_dev',
        'iCuD_dev',
        'iCcD_dev',
        'iCpD_dev',
        'ic_p_dev',
        'incD_dev',
    ];
    const cookiesTestName = [
        'token_test',
        'incserveToken_test',
        'iCuD_test',
        'iCcD_test',
        'iCpD_test',
        'ic_p_test',
        'incD_test',
    ];
    const cookiesProdName = [
        'token_prod',
        'incserveToken_prod',
        'iCuD_prod',
        'iCcD_prod',
        'iCpD_prod',
        'ic_p_prod',
        'incD_prod',
    ];
    if (envMode === 'dev') {
        cookieNames = [...cookiesTestName, ...cookiesProdName];
    } else if (envMode === 'test') {
        cookieNames = [...cookiesDevName, ...cookiesProdName];
    } else {
        cookieNames = [...cookiesTestName, ...cookiesDevName];
    }
    cookieNames.forEach((name) =>
        Cookies.remove(`${name}_${envMode}`, { path: '/', domain: window.location.hostname })
    );
};

export const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export const shareOnWhatsApp = (jobLink) => {
    const url = `https://wa.me/?text=${encodeURIComponent(jobLink)}`;
    window.open(url, '_blank');
};

export const shareOnLinkedIn = (jobLink) => {
    const linkedinShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(jobLink)}`;
    window.open(linkedinShareURL, '_blank');
};

export const shareOnFacebook = (jobLink) => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(jobLink)}`;
    window.open(url, '_blank');
};

export const shareOnTwitter = (jobLink) => {
    const url = `https://twitter.com/intent/tweet?text=Check out this job opportunity&url=${encodeURIComponent(jobLink)}`;
    window.open(url, '_blank');
};

export const copyToClipboard = (jobLink) => {
    navigator.clipboard.writeText(jobLink).then(() => {
        toast.success(<Toast msg="Link copied to clipboard." />, {
            autoClose: 2000,
            toastId: 'Link copied to clipboard.',
        });
    });
};
