import {
    GET_CANDIDATE,
    GET_CANDIDATE_SUCCESS,
    GET_CANDIDATE_FAILURE,
    POST_CANDIDATE,
    POST_CANDIDATE_SUCCESS,
    POST_CANDIDATE_FAILURE,
    CANCEL_INTERVIEW,
    CANCEL_INTERVIEW_SUCCESS,
    CANCEL_INTERVIEW_FAILURE,
    POST_BULK_CANDIDATE,
    POST_BULK_CANDIDATE_SUCCESS,
    POST_BULK_CANDIDATE_FAILURE,
    GET_DROPDOWN_QUESTION,
    GET_DROPDOWN_QUESTION_SUCCESS,
    GET_DROPDOWN_QUESTION_FAILURE,
    GET_DROPDOWN_POSITION,
    GET_DROPDOWN_POSITION_SUCCESS,
    GET_DROPDOWN_POSITION_FAILURE,
    RESEND_EMAIL,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_FAILURE,
    COMPARE_JD_CV,
    COMPARE_JD_CV_SUCCESS,
    COMPARE_JD_CV_FAILURE,
    RESUME_INTERVIEW,
    RESUME_INTERVIEW_SUCCESS,
    RESUME_INTERVIEW_FAILURE,
    MANUAL_INVITE,
    MANUAL_INVITE_SUCCESS,
    MANUAL_INVITE_FAILURE,
    FETCH_RESUME_SCORE,
    FETCH_RESUME_SCORE_SUCCESS,
    FETCH_RESUME_SCORE_FAILURE,
    MANUAL_CALL_END,
    MANUAL_CALL_END_SUCCESS,
    MANUAL_CALL_END_FAILURE,
    ADD_CANDIDATE_BANK,
    ADD_CANDIDATE_BANK_SUCCESS,
    ADD_CANDIDATE_BANK_FAILURE,
    GET_CANDIDATE_BANK,
    GET_CANDIDATE_BANK_SUCCESS,
    GET_CANDIDATE_BANK_FAILURE,
    EDIT_CANDIDATE_BANK,
    EDIT_CANDIDATE_BANK_SUCCESS,
    EDIT_CANDIDATE_BANK_FAILURE,
    DELETE_CANDIDATE_BANK,
    DELETE_CANDIDATE_BANK_SUCCESS,
    DELETE_CANDIDATE_BANK_FAILURE,
    GET_CANDIDATE_BANK_LIST,
    GET_CANDIDATE_BANK_LIST_SUCCESS,
    GET_CANDIDATE_BANK_LIST_FAILURE,
    DELETE_CANDIDATE_BANK_LIST,
    DELETE_CANDIDATE_BANK_LIST_SUCCESS,
    DELETE_CANDIDATE_BANK_LIST_FAILURE,
    ADD_EDIT_CANDIDATE_BANK_LIST,
    ADD_EDIT_CANDIDATE_BANK_LIST_SUCCESS,
    ADD_EDIT_CANDIDATE_BANK_LIST_FAILURE,
    GET_CANDIDATE_BANK_DROPDOWN_LIST,
    GET_CANDIDATE_BANK_DROPDOWN_LIST_SUCCESS,
    GET_CANDIDATE_BANK_DROPDOWN_LIST_FAILURE,
    CHECK_CANDIDATE_BANK_EXISTS,
    CHECK_CANDIDATE_BANK_EXISTS_SUCCESS,
    CHECK_CANDIDATE_BANK_EXISTS_FAILURE,
    PUT_FINAL_OUTCOME,
    PUT_FINAL_OUTCOME_SUCCESS,
    PUT_FINAL_OUTCOME_FAILURE,
} from './types';
export const fetchResumeScore = (payload) => ({
    type: FETCH_RESUME_SCORE,
    payload,
});
export const fetchResumeScoreSuccess = (payload) => ({
    type: FETCH_RESUME_SCORE_SUCCESS,
    payload,
});
export const fetchResumeScoreFailure = () => ({
    type: FETCH_RESUME_SCORE_FAILURE,
});
export const getDropdownQuestion = (payload) => ({
    type: GET_DROPDOWN_QUESTION,
    payload,
});
export const getDropdownQuestionSuccess = (payload) => ({
    type: GET_DROPDOWN_QUESTION_SUCCESS,
    payload,
});
export const getDropdownQuestionFailure = () => ({
    type: GET_DROPDOWN_QUESTION_FAILURE,
});
export const getDropdownPosition = (payload) => ({
    type: GET_DROPDOWN_POSITION,
    payload,
});
export const getDropdownPositionSuccess = (payload) => ({
    type: GET_DROPDOWN_POSITION_SUCCESS,
    payload,
});
export const getDropdownPositionFailure = () => ({
    type: GET_DROPDOWN_POSITION_FAILURE,
});
export const getCandidate = (payload) => ({
    type: GET_CANDIDATE,
    payload,
});
export const getCandidateSuccess = (payload) => ({
    type: GET_CANDIDATE_SUCCESS,
    payload,
});
export const getCandidateFailure = () => ({
    type: GET_CANDIDATE_FAILURE,
});
export const createOrUpdateCandidate = (payload) => ({
    type: POST_CANDIDATE,
    payload,
});
export const createOrUpdateCandidateSuccess = () => ({
    type: POST_CANDIDATE_SUCCESS,
});
export const createOrUpdateCandidateFailure = () => ({
    type: POST_CANDIDATE_FAILURE,
});
export const createBulkCandidate = (payload) => ({
    type: POST_BULK_CANDIDATE,
    payload,
});
export const createBulkCandidateSuccess = (payload) => ({
    type: POST_BULK_CANDIDATE_SUCCESS,
    payload,
});
export const createBulkCandidateFailure = () => ({
    type: POST_BULK_CANDIDATE_FAILURE,
});
export const cancelInterview = (payload) => ({
    type: CANCEL_INTERVIEW,
    payload,
});
export const cancelInterviewSuccess = () => ({
    type: CANCEL_INTERVIEW_SUCCESS,
});
export const cancelInterviewFailure = () => ({
    type: CANCEL_INTERVIEW_FAILURE,
});

export const resendEmail = (payload) => ({
    type: RESEND_EMAIL,
    payload,
});
export const resendEmailSuccess = () => ({
    type: RESEND_EMAIL_SUCCESS,
});
export const resendEmailFailure = () => ({
    type: RESEND_EMAIL_FAILURE,
});

export const compareJDCV = (payload) => ({
    type: COMPARE_JD_CV,
    payload,
});
export const compareJDCVSuccess = (payload) => ({
    type: COMPARE_JD_CV_SUCCESS,
    payload,
});
export const compareJDCVFailure = () => ({
    type: COMPARE_JD_CV_FAILURE,
});
export const resumeInterview = (payload) => ({
    type: RESUME_INTERVIEW,
    payload,
});
export const resumeInterviewSuccess = () => ({
    type: RESUME_INTERVIEW_SUCCESS,
});
export const resumeInterviewFailure = () => ({
    type: RESUME_INTERVIEW_FAILURE,
});
export const manualInvite = (payload) => ({
    type: MANUAL_INVITE,
    payload,
});
export const manualInviteSuccess = () => ({
    type: MANUAL_INVITE_SUCCESS,
});
export const manualInviteFailure = () => ({
    type: MANUAL_INVITE_FAILURE,
});
export const manualCallEnd = (payload) => ({
    type: MANUAL_CALL_END,
    payload,
});
export const manualCallEndSuccess = () => ({
    type: MANUAL_CALL_END_SUCCESS,
});
export const manualCallEndFailure = () => ({
    type: MANUAL_CALL_END_FAILURE,
});

export const addCandidateBank = (payload) => ({
    type: ADD_CANDIDATE_BANK,
    payload,
});

export const addCandidateBankSuccess = () => ({
    type: ADD_CANDIDATE_BANK_SUCCESS,
});

export const addCandidateBankFailure = () => ({
    type: ADD_CANDIDATE_BANK_FAILURE,
});

export const getCandidateBank = (payload) => ({
    type: GET_CANDIDATE_BANK,
    payload,
});

export const getCandidateBankSuccess = (payload) => ({
    type: GET_CANDIDATE_BANK_SUCCESS,
    payload,
});

export const getCandidateBankFailure = () => ({
    type: GET_CANDIDATE_BANK_FAILURE,
});

export const editCandidateBank = (payload) => ({
    type: EDIT_CANDIDATE_BANK,
    payload,
});

export const editCandidateBankSuccess = () => ({
    type: EDIT_CANDIDATE_BANK_SUCCESS,
});

export const editCandidateBankFailure = () => ({
    type: EDIT_CANDIDATE_BANK_FAILURE,
});

export const deleteCandidateBank = (payload) => ({
    type: DELETE_CANDIDATE_BANK,
    payload,
});

export const deleteCandidateBankSuccess = () => ({
    type: DELETE_CANDIDATE_BANK_SUCCESS,
});

export const deleteCandidateBankFailure = () => ({
    type: DELETE_CANDIDATE_BANK_FAILURE,
});

export const getCandidateBankList = (payload) => ({
    type: GET_CANDIDATE_BANK_LIST,
    payload,
});

export const getCandidateBankListSuccess = (payload) => ({
    type: GET_CANDIDATE_BANK_LIST_SUCCESS,
    payload,
});

export const getCandidateBankListFailure = () => ({
    type: GET_CANDIDATE_BANK_LIST_FAILURE,
});

export const deleteCandidateBankList = (payload) => ({
    type: DELETE_CANDIDATE_BANK_LIST,
    payload,
});

export const deleteCandidateBankListSuccess = () => ({
    type: DELETE_CANDIDATE_BANK_LIST_SUCCESS,
});

export const deleteCandidateBankListFailure = () => ({
    type: DELETE_CANDIDATE_BANK_LIST_FAILURE,
});

export const addEditCandidateBankList = (payload) => ({
    type: ADD_EDIT_CANDIDATE_BANK_LIST,
    payload,
});

export const addEditCandidateBankListSuccess = () => ({
    type: ADD_EDIT_CANDIDATE_BANK_LIST_SUCCESS,
});

export const addEditCandidateBankListFailure = () => ({
    type: ADD_EDIT_CANDIDATE_BANK_LIST_FAILURE,
});

export const getCandidateBankDropdownList = () => ({
    type: GET_CANDIDATE_BANK_DROPDOWN_LIST,
});

export const getCandidateBankDropdownListSuccess = (payload) => ({
    type: GET_CANDIDATE_BANK_DROPDOWN_LIST_SUCCESS,
    payload,
});

export const getCandidateBankDropdownListFailure = () => ({
    type: GET_CANDIDATE_BANK_DROPDOWN_LIST_FAILURE,
});

export const checkCandidateBankExists = (payload) => ({
    type: CHECK_CANDIDATE_BANK_EXISTS,
    payload,
});

export const checkCandidateBankExistsSuccess = (payload) => ({
    type: CHECK_CANDIDATE_BANK_EXISTS_SUCCESS,
    payload,
});

export const checkCandidateBankExistsFailure = (payload) => ({
    type: CHECK_CANDIDATE_BANK_EXISTS_FAILURE,
    payload,
});

export const putFinalOutcome = (payload) => ({
    type: PUT_FINAL_OUTCOME,
    payload,
});
export const putFinalOutcomeSuccess = () => ({
    type: PUT_FINAL_OUTCOME_SUCCESS,
});
export const putFinalOutcomeFailure = () => ({
    type: PUT_FINAL_OUTCOME_FAILURE,
});
