export const QUESTION_CONSTRAINTS = {
    MIN_CHAR: 10,
    MAX_CHAR: 750,
    MAX_CODING_CHAR: 2000,
};

export const ERROR_MESSAGES = {
    MIN_CHAR_ERROR: `Question must be at least ${QUESTION_CONSTRAINTS.MIN_CHAR} characters long`,
    MAX_CHAR_ERROR: `Question must be not more than ${QUESTION_CONSTRAINTS.MAX_CHAR} characters long`,
    MAX_CODING_CHAR_ERROR: `Question must be not more than ${QUESTION_CONSTRAINTS.MAX_CODING_CHAR} characters long`,
    SAVE_QUESTION: 'Please save the question to continue',
    SAVE_ERROR: 'Please save the question(s) to continue',
    EMPTY_QUESTION: "Question can't be empty",
    EMPTY_ANSWER: "Answer can't be empty",
    QUESTION_ALREADY_EXISTS: 'Question already exists',
    TOTAL_QUESTION_LIMIT: "Total question can't be more than 20",
    QUESTIONS_NOT_AVAILABLE: 'Questions are not available',
    SELECT_POSITION: 'Please select position',
    ADD_CUSTOM_QUESTION: 'Please save your last question before adding another custom question',
    MINIMUM_QUESTION_LENGTH: 'Minimum 5 questions are required to continue',
};

export const QUESTION_TYPES = {
    GLOBAL: 'global',
    CUSTOM: 'custom',
    CODING: 'coding',
    AI_GENERATED: 'ai-generated',
};

export const QUESTION_TYPE_LABEL = {
    AI: 'AI',
    CODING: 'Coding',
    CUSTOM: 'Custom',
};

export const DIFFICULTY_LEVELS = {
    EASY: 'Easy',
    MEDIUM: 'Medium',
    HARD: 'Hard',
};

export const PROGRAMMING_LANGUAGES = {
    NA: 'No Preference',
    CPP: 'C++',
    JAVA: 'Java',
    JAVASCRIPT: 'Javascript',
    C: 'C',
    PYTHON: 'Python',
    PHP: 'PHP',
    CSHARP: 'C#',
    SWIFT: 'Swift',
    DART: 'Dart',
    SQL: 'SQL',
};

export const CODE_DURATIONS = [
    { value: 900, label: '15 mins' },
    { value: 1200, label: '20 mins' },
    { value: 1500, label: '25 mins' },
    { value: 1800, label: '30 mins' },
    { value: 2100, label: '35 mins' },
    { value: 2400, label: '40 mins' },
    { value: 2700, label: '45 mins' },
];

export const CODE_DURATIONS_SQL = [
    { value: 300, label: '5 mins' },
    { value: 600, label: '10 mins' },
    { value: 900, label: '15 mins' },
    { value: 1200, label: '20 mins' },
    { value: 1500, label: '25 mins' },
];

export const CODE_DURATIONS_RANGE = (level) => {
    let tempCodingDuration = [
        { value: 900, label: '15 mins' },
        { value: 1200, label: '20 mins' },
        { value: 1500, label: '25 mins' },
        { value: 1800, label: '30 mins' },
        { value: 2100, label: '35 mins' },
        { value: 2400, label: '40 mins' },
        { value: 2700, label: '45 mins' },
    ];
    if (level === DIFFICULTY_LEVELS.MEDIUM) {
        tempCodingDuration = tempCodingDuration.filter((data) => data?.value >= 1800);
    } else if (level === DIFFICULTY_LEVELS.HARD) {
        tempCodingDuration = tempCodingDuration.filter((data) => data?.value >= 2400);
    }
    return tempCodingDuration;
};

export const DEFAULT_GENERATE_QUESTIONS = {
    question: '',
    codeBlock: '',
    answer: '',
    questionType: QUESTION_TYPES.CUSTOM,
    timeToPrepare: 10,
    timeToAnswer: 120,
};

export const DEFAULT_GENERATE_CODING_QUESTIONS = {
    question: '',
    answer: '',
    programmingLanguage: PROGRAMMING_LANGUAGES.NA,
    questionType: QUESTION_TYPES.CODING,
    timeToPrepare: 0,
    timeToAnswer: CODE_DURATIONS[0].value,
    difficultyLevel: DIFFICULTY_LEVELS.EASY,
};

export const QUESTION = {
    QUESTION: 'question',
    CODE_BLOCK: 'codeBlock',
    QUESTION_ID: 'questionId',
    EDITABLE_ANSWER: 'editableAnswer',
    ANSWER: 'answer',
    QUESTION_TYPE: 'questionType',
    TIME_TO_PREPARE: 'timeToPrepare',
    TIME_TO_ANSWER: 'timeToAnswer',
    IS_EDITABLE: 'isEditable',
    EDITABLE_QUESTION: 'editableQuestion',
    ERROR: 'error',
    DIFFICULTY_LEVEL: 'difficultyLevel',
    PROGRAMMING_LANGUAGE: 'programmingLanguage',
    MCQ_TYPE: 'mcqType',
    NO_OF_QUESTIONS: 'noOfQuestions',
    MCQ_TIME_DURATION: 'mcqTimeDuration',
};

export const NO_OF_QUESTIONS_LIST = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
    { value: 35, label: '35' },
    { value: 40, label: '40' },
    { value: 45, label: '45' },
    { value: 50, label: '50' },
    { value: 55, label: '55' },
    { value: 60, label: '60' },
];

export const MCQ_TIME_DURATION_LIST = [
    { value: 300, label: '5 mins' },
    { value: 600, label: '10 mins' },
    { value: 900, label: '15 mins' },
    { value: 1200, label: '20 mins' },
    { value: 1500, label: '25 mins' },
    { value: 1800, label: '30 mins' },
    { value: 2100, label: '35 mins' },
    { value: 2400, label: '40 mins' },
    { value: 2700, label: '45 mins' },
    { value: 3000, label: '50 mins' },
    { value: 3300, label: '55 mins' },
    { value: 3600, label: '60 mins' },
];

export const MCQ_TYPE_QUESTIONS = [
    { value: 'logical_reasoning', label: 'Logical Reasoning' },
    { value: 'quantitative', label: 'Quantitative' },
    { value: 'number_system', label: 'Numerical Reasoning' },
    { value: 'verbal_reasoning', label: 'Verbal Reasoning' },
];

export const MCQ_TYPE_TEXT = {
    logical_reasoning: 'Logical Reasoning',
    quantitative: 'Quantitative',
    number_system: 'Numerical Reasoning',
    verbal_reasoning: 'Verbal Reasoning',
};

export const QUESTION_TYPE_OPTIONS = [
    { value: QUESTION_TYPES.CUSTOM, label: 'Custom Question' },
    { value: QUESTION_TYPES.CODING, label: 'Coding Question' },
];

export const QUESTION_TYPE_OPTIONS_CODING_DISABLED = [
    { value: QUESTION_TYPES.CUSTOM, label: 'Custom Question', isDisabled: false },
    { value: QUESTION_TYPES.CODING, label: 'Coding Question', isDisabled: true },
];

export const DIFFICULTY_LEVEL_OPTIONS = [
    { value: DIFFICULTY_LEVELS.EASY, label: DIFFICULTY_LEVELS.EASY },
    { value: DIFFICULTY_LEVELS.MEDIUM, label: DIFFICULTY_LEVELS.MEDIUM },
    { value: DIFFICULTY_LEVELS.HARD, label: DIFFICULTY_LEVELS.HARD },
];
export const PROGRAMMING_LANGUAGE_OPTIONS = [
    { value: PROGRAMMING_LANGUAGES.NA, label: PROGRAMMING_LANGUAGES.NA },
    { value: PROGRAMMING_LANGUAGES.C, label: PROGRAMMING_LANGUAGES.C },
    { value: PROGRAMMING_LANGUAGES.CPP, label: PROGRAMMING_LANGUAGES.CPP },
    { value: PROGRAMMING_LANGUAGES.CSHARP, label: PROGRAMMING_LANGUAGES.CSHARP },
    { value: PROGRAMMING_LANGUAGES.DART, label: PROGRAMMING_LANGUAGES.DART },
    { value: PROGRAMMING_LANGUAGES.JAVA, label: PROGRAMMING_LANGUAGES.JAVA },
    { value: PROGRAMMING_LANGUAGES.JAVASCRIPT, label: PROGRAMMING_LANGUAGES.JAVASCRIPT },
    { value: PROGRAMMING_LANGUAGES.PYTHON, label: PROGRAMMING_LANGUAGES.PYTHON },
    { value: PROGRAMMING_LANGUAGES.PHP, label: PROGRAMMING_LANGUAGES.PHP },
    { value: PROGRAMMING_LANGUAGES.SWIFT, label: PROGRAMMING_LANGUAGES.SWIFT },
    { value: PROGRAMMING_LANGUAGES.SQL, label: PROGRAMMING_LANGUAGES.SQL },
];
export const STATUSES = {
    ACTIVE: 'Active',
    OPEN_POSITION: 'Open Position',
    CLOSED: 'Closed',
    CLOSED_POSITION: 'Closed Position',
    CANCELED: 'Canceled',
    CAUTIOUSLY_RECOMMENDED: 'Cautiously Recommended',
    IN_PROGRESS: 'In Progress',
    INVITED: 'Invited',
    INVITE_SENT: 'Invite Sent',
    NOT_RECOMMENDED: 'Not Recommended',
    PENDING: 'Pending',
    RECOMMENDED: 'Recommended',
    UNATTENDED: 'Unattended',
    NETWORK_DISCONNECTED: 'Network Disconnected',
    REINVITED: 'Reinvited',
    RESUME_REJECTED: 'Resume Rejected',
    INCOMPLETE_DATA: 'Incomplete Data',
    COMPLETED: 'Completed',
};
