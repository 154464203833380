import {
    GENERATE_ANSWER,
    GENERATE_ANSWER_SUCCESS,
    GENERATE_ANSWER_FAILURE,
    GENERATE_QUESTION,
    GENERATE_QUESTION_SUCCESS,
    GENERATE_QUESTION_FAILURE,
    GET_QUESTIONS,
    GET_QUESTIONS_SUCCESS,
    GET_QUESTIONS_FAILURE,
    POST_QUESTION,
    POST_QUESTION_SUCCESS,
    POST_QUESTION_FAILURE,
    CODING_PERMISSION,
    CODING_PERMISSION_SUCCESS,
    CODING_PERMISSION_FAILURE,
    GET_QUESTION_LIBRARY,
    GET_QUESTION_LIBRARY_SUCCESS,
    GET_QUESTION_LIBRARY_FAILURE,
    APTITUDE_PERMISSION,
    APTITUDE_PERMISSION_SUCCESS,
    APTITUDE_PERMISSION_FAILURE,
    CONVERSATION_PERMISSION,
    CONVERSATION_PERMISSION_SUCCESS,
    CONVERSATION_PERMISSION_FAILURE,
} from '../action/types';
const INIT_STATE = {
    loading: false,
    lists: null,
    questions: null,
    answer: null,
    coding: false,
    aptitudePermission: false,
    conversation: false,
    questionLibrary: [],
};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONVERSATION_PERMISSION:
            return { ...state, conversation: false };
        case CONVERSATION_PERMISSION_SUCCESS:
            return { ...state, conversation: action.payload };
        case CONVERSATION_PERMISSION_FAILURE:
            return { ...state, conversation: false };
        case CODING_PERMISSION:
            return { ...state };
        case CODING_PERMISSION_SUCCESS:
            return {
                ...state,
                coding: action.payload,
            };
        case CODING_PERMISSION_FAILURE:
            return { ...state };
        case APTITUDE_PERMISSION:
            return { ...state };
        case APTITUDE_PERMISSION_SUCCESS:
            return {
                ...state,
                aptitudePermission: action.payload,
            };
        case APTITUDE_PERMISSION_FAILURE:
            return { ...state };
        case GET_QUESTIONS:
            return { ...state, loading: true };
        case GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                lists: action.payload,
            };
        case GET_QUESTIONS_FAILURE:
            return { ...state, loading: false };
        case POST_QUESTION:
            return { ...state, loading: true };
        case POST_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_QUESTION_FAILURE:
            return { ...state, loading: false };
        case GENERATE_ANSWER:
            return { ...state, loading: true };
        case GENERATE_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                answer: action.payload,
            };
        case GENERATE_ANSWER_FAILURE:
            return { ...state, loading: false };
        case GENERATE_QUESTION:
            return { ...state, loading: true };
        case GENERATE_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                questions: action.payload,
            };
        case GENERATE_QUESTION_FAILURE:
            return { ...state, loading: false };

        case GET_QUESTION_LIBRARY:
            return { ...state, loading: true };
        case GET_QUESTION_LIBRARY_SUCCESS:
            return { ...state, loading: false, questionLibrary: action.payload?.questions };
        case GET_QUESTION_LIBRARY_FAILURE:
            return { ...state, loading: false };

        default:
            return state;
    }
};
